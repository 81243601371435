import * as React from 'react';
import '../App.css';
import AppBar from '../component/appbar';
import Foot from '../component/foot';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function Page1(){
    return(
    <div className="App" >
        <AppBar />
         <header className="App-header" >
            <Box sx={{ display: 'flex' , flexWrap : 'wrap' , justifyContent: 'center', py : 7}}>
         <Card sx={{ maxWidth: 300 , m: 3}}>
      <CardMedia
        sx={{ height: 300 }}
        image="https://i.pinimg.com/1200x/cf/7c/77/cf7c7771d30e6c74a70b273a7de0efef.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

    <Card sx={{ maxWidth: 300 , m: 3}}>
      <CardMedia
        sx={{ height: 300 }}
        image="https://i.pinimg.com/1200x/3a/26/93/3a2693370d15e25c6f604f823befa7f3.jpg"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </Box>
        </header>
        <Foot />
    </div>

    );
    }
    export default Page1;
import * as React from 'react';
import { useState } from 'react'; // Import useState hook
import '../App.css';
import AppBar from '../component/appbar';
import Foot from '../component/foot';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



function Page2() {
    const [age, setAge] = React.useState("");

    // Function to handle dropdown changes
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const Button_click = () => {
        alert(age ? age : 'No age selected');
    };
    return (
        <div className="App">
            <AppBar />
            <header className="App-header">
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', py: 7 }}>
                    <Card sx={{ maxWidth: 300, m: 3 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image="https://i.pinimg.com/1200x/cf/7c/77/cf7c7771d30e6c74a70b273a7de0efef.jpg"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                oioi
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                <Box sx={{ minWidth: 250 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age} // Bind value to the state
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="success" onClick={Button_click}>
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
                {/* Display selected age */}
              
            </header>
            <Foot />
        </div>
    );
}

export default Page2;

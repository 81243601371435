import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';




function Foot() {
  

  return (
    <AppBar position="fixed"  sx={{ top: 'auto', bottom: 0, bgcolor: 'success.main'  }}>
        <Toolbar>
        
        </Toolbar>
      </AppBar>
  );
}
export default Foot;